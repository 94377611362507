<template>
  <section class="section">
    <router-view />
  </section>
</template>


<script>
export default {
  name: "Auth",
  data() {
    return {};
  },
};
</script>